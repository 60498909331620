/**
 * IconSelect
 * @module components/IconSelect
 */

import React, { useState, useEffect, useContext } from 'react';
import { LocalizationContext } from 'context/localization.context';
import { useToggle } from 'hooks';
import Tooltip from '../Tooltip';
import { LinkModel } from 'types/common';
import clsx from 'clsx';
import Text from 'components/ui/Text';
import Icon, { iconTypes } from '../Icon/Icon';

interface IconSelectmodel {
	/** Items  */
	items: any[];

	/** Icon next to IconSelect text   */
	icon: iconTypes;

	/** Id for IconSelect */
	id: string;

	/** Selected item */
	selected: any;

	/** Callback function */
	onChange: (item: LinkModel) => void;

	/** Optional tooltip for warehouse select */
	warehouseTooltip?: string;

	loading?: boolean;

	salesUser?: boolean;

	handleOpenCustomerSearch?: () => void;
}

const IconSelect = React.memo(
	({
		items,
		icon,
		id,
		selected,
		onChange,
		warehouseTooltip,
		loading,
		salesUser,
		handleOpenCustomerSearch,
	}: IconSelectmodel) => {
		const { t }: any = useContext(LocalizationContext);
		const [expanded, toggle, ref] = useToggle(false, true);
		const [selectedItem, setSelectedItem] = useState<any>(selected);
		const selectedItemLabel = selectedItem?.name;

		useEffect(() => {
			setSelectedItem(selected);
		}, [selected]);

		const handleItemClick = (e: React.MouseEvent, item: LinkModel) => {
			if (item.name) {
				e.preventDefault();
			}

			if (onChange) {
				onChange(item);
			}

			setSelectedItem(item);
			toggle();
		};

		return (
			<div ref={ref} className="icon-select">
				<a
					href={`#${id}`}
					aria-controls={id}
					aria-expanded={expanded}
					role="button"
					onClick={(e) => {
						e.preventDefault();
						!loading && toggle();
					}}
					title={
						loading
							? `${t('shared/header/loading')}...`
							: id === 'customers'
							? `${selectedItem.name} - ${selectedItem.externId}`
							: `${selectedItem.name}`
					}
					className={clsx(
						'flex my-2 items-center',
						loading && 'opacity-50 cursor-not-allowed'
					)}
				>
					<Icon icon={icon} size={1.25} color="#0075BF" />
					<span
						className={clsx(
							warehouseTooltip ? 'mr-12' : 'mr-4',
							'lg:whitespace-nowrap lg:overflow-hidden lg:overflow-ellipsis lg:w-30 text-p text-black ml-2'
						)}
					>
						{loading ? `${t('shared/header/loading')}...` : selectedItemLabel}
					</span>
					{expanded ? (
						<Icon
							icon="chevron"
							size={0.75}
							direction="down"
							color="#0075BF"
							className="mt-1 my-auto mx-0 absolute right-0"
						/>
					) : (
						<Icon
							icon="chevron"
							size={0.75}
							direction="up"
							color="#0075BF"
							className="mt-1 my-auto mx-0 absolute right-0"
						/>
					)}
				</a>

				<div
					className={clsx(
						items && items.length > 0 && expanded ? 'block' : 'hidden',
						'min-w-full lg:absolute lg:right-0 lg:left-auto left-0 bg-white z-100 lg:px-3 px-0 py-1  border-greyDark lg:border-1 border-t'
					)}
					id={id}
					aria-hidden={!expanded}
					tabIndex={expanded ? -1 : undefined}
				>
					{items && (
						<ul className="list-none m-0 p-0 overflow-y-auto max-h-113">
							{items.map((item, i) => {
								return (
									<li className="mr-6 whitespace-nowrap" key={i}>
										<abbr className="no-underline" title={item.name}>
											<a
												title={
													id === 'customers'
														? `${item.name} - ${item.externId}`
														: item.name
												}
												className="hover:underline text-p text-black truncate max-w-65 block"
												href="#"
												onClick={(e) => handleItemClick(e, item)}
											>
												{item.name}
												{id !== 'warehouses' && id !== 'projects' && (
													<>
														<br />
														<Text as="p" styleAs="span">
															{item?.externId}
														</Text>
													</>
												)}
											</a>
										</abbr>
									</li>
								);
							})}
						</ul>
					)}
					{salesUser && (
						<button
							onClick={handleOpenCustomerSearch}
							className="mx-auto w-full text-xsm text-white hover:bg-orange-hover font-standard button bg-orange border-orange border-2 border-solid font-bold align-middle text-center cursor-pointer inline-block uppercase mb-3 mt-4 py-2 px-3 rounded-lg no-underline smMax:py-0.5 smMax:px-1.5 smMax:text-sm disabled:text-greyDarker disabled:bg-grey disabled:border-grey disabled:border-solid disabled:border-2 smMax:undefined"
						>
							{t('shared/header/switchcustomer')}
						</button>
					)}
				</div>

				{warehouseTooltip && (
					<div className="inline-block absolute top-0 right-0 mt-1 mr-5">
						<Tooltip title={t('menuoptions/warehousehelp')}>
							<p className="text-xsm m-0">{warehouseTooltip}</p>
						</Tooltip>
					</div>
				)}
			</div>
		);
	}
);

export default IconSelect;
